import { FlosIconsApi } from './flos-icons-api';
import { FlosIconElement } from './flos-icons-element';
import { CoopAliasedShapes } from './shapes/coop-aliased-shapes';
import { CoopShapes } from './shapes/coop-shapes-generated';
import { AnimatedShapes } from './shapes/animated-shapes';

declare var window: Window;
interface Window {
  FlosIcons: FlosIconsApi;
}

if (typeof window !== 'undefined') {
  window.FlosIcons = window.FlosIcons || FlosIconsApi.instance;
}

window.FlosIcons.add(CoopShapes);
window.FlosIcons.add(CoopAliasedShapes);
window.FlosIcons.add(AnimatedShapes);

if (!customElements.get('flos-icon')) {
  // @ts-ignore
  customElements.define('flos-icon', FlosIconElement);
}

const { FlosIcons } = window;
export { FlosIcons };
