import {descriptorConfig} from '../utils/descriptor-config';
import {CoopShapes} from './coop-shapes-generated';

const CoopAliasedShapes: { [key: string]: string } = {};

Object.defineProperty(CoopAliasedShapes, 'agricultural-insurance', descriptorConfig(CoopShapes['blad']));
Object.defineProperty(CoopAliasedShapes, 'armchair', descriptorConfig(CoopShapes['laenestol']));
Object.defineProperty(CoopAliasedShapes, 'arrow', descriptorConfig(CoopShapes['arrow-right']));
Object.defineProperty(CoopAliasedShapes, 'attache', descriptorConfig(CoopShapes['kufferter']));
Object.defineProperty(CoopAliasedShapes, 'backpack', descriptorConfig(CoopShapes['kufferter']));
Object.defineProperty(CoopAliasedShapes, 'band-aid', descriptorConfig(CoopShapes['plaster']));
Object.defineProperty(CoopAliasedShapes, 'bell', descriptorConfig(CoopShapes['ur_kl8']));
Object.defineProperty(CoopAliasedShapes, 'bike', descriptorConfig(CoopShapes['cykel']));
Object.defineProperty(CoopAliasedShapes, 'bill', descriptorConfig(CoopShapes['print_kr']));
Object.defineProperty(CoopAliasedShapes, 'bills', descriptorConfig(CoopShapes['print_kr']));
Object.defineProperty(CoopAliasedShapes, 'business-info', descriptorConfig(CoopShapes['data']));
Object.defineProperty(CoopAliasedShapes, 'cake', descriptorConfig(CoopShapes['kokkehue']));
Object.defineProperty(CoopAliasedShapes, 'calendar-03', descriptorConfig(CoopShapes['kalender2']));
Object.defineProperty(CoopAliasedShapes, 'calendar-no-date', descriptorConfig(CoopShapes['kalender2']));
Object.defineProperty(CoopAliasedShapes, 'calendar', descriptorConfig(CoopShapes['kalender1']));
Object.defineProperty(CoopAliasedShapes, 'caravan', descriptorConfig(CoopShapes['telt']));
Object.defineProperty(CoopAliasedShapes, 'car-crash-single', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-crash-two-cars', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-engine', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-front-damage', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-front', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-hood-open', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-key', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-mini-side', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-repair', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-sedan-side', descriptorConfig(CoopShapes['bil2']));
Object.defineProperty(CoopAliasedShapes, 'car-service', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-stationcar-side', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-van-side', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-wash-02', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-wash-03', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'car-wash', descriptorConfig(CoopShapes['bil']));
Object.defineProperty(CoopAliasedShapes, 'carry-on', descriptorConfig(CoopShapes['kufferter']));
Object.defineProperty(CoopAliasedShapes, 'cat', descriptorConfig(CoopShapes['kat']));
Object.defineProperty(CoopAliasedShapes, 'chat-bot-blue-eyes', descriptorConfig(CoopShapes['kundesupport1']));
Object.defineProperty(CoopAliasedShapes, 'chat-bot-black-eyes', descriptorConfig(CoopShapes['kundesupport1']));
Object.defineProperty(CoopAliasedShapes, 'chat-bot', descriptorConfig(CoopShapes['kundesupport1']));
Object.defineProperty(CoopAliasedShapes, 'chat-face', descriptorConfig(CoopShapes['kundesupport1']));
Object.defineProperty(CoopAliasedShapes, 'chat-message', descriptorConfig(CoopShapes['kundesupport2']));
Object.defineProperty(CoopAliasedShapes, 'chat', descriptorConfig(CoopShapes['kundesupport2']));
Object.defineProperty(CoopAliasedShapes, 'chat-sender-robot', descriptorConfig(CoopShapes['fagpersonale3']));
Object.defineProperty(CoopAliasedShapes, 'chat-sender-support', descriptorConfig(CoopShapes['kundesupport2']));
Object.defineProperty(CoopAliasedShapes, 'circle-check', descriptorConfig(CoopShapes['tjek']));
Object.defineProperty(CoopAliasedShapes, 'circle-close', descriptorConfig(CoopShapes['luk']));
Object.defineProperty(CoopAliasedShapes, 'circle-edit', descriptorConfig(CoopShapes['blyant']));
Object.defineProperty(CoopAliasedShapes, 'circle-minus', descriptorConfig(CoopShapes['skraldespand']));
Object.defineProperty(CoopAliasedShapes, 'circle-plus', descriptorConfig(CoopShapes['plus']));
Object.defineProperty(CoopAliasedShapes, 'circle-star', descriptorConfig(CoopShapes['3_stjerner']));
Object.defineProperty(CoopAliasedShapes, 'circle-trash', descriptorConfig(CoopShapes['skraldespand']));
Object.defineProperty(CoopAliasedShapes, 'clock-around', descriptorConfig(CoopShapes['ur_history']));
Object.defineProperty(CoopAliasedShapes, 'clock', descriptorConfig(CoopShapes['ur_kl8']));
Object.defineProperty(CoopAliasedShapes, 'cloud', descriptorConfig(CoopShapes['sky']));
Object.defineProperty(CoopAliasedShapes, 'computer-screen', descriptorConfig(CoopShapes['tv']));
Object.defineProperty(CoopAliasedShapes, 'cone', descriptorConfig(CoopShapes['kegle']));
Object.defineProperty(CoopAliasedShapes, 'couch', descriptorConfig(CoopShapes['laenestol']));
Object.defineProperty(CoopAliasedShapes, 'credit-card', descriptorConfig(CoopShapes['kreditkort1']));
Object.defineProperty(CoopAliasedShapes, 'cutlery', descriptorConfig(CoopShapes['skaerebraet_kniv']));
Object.defineProperty(CoopAliasedShapes, 'damage', descriptorConfig(CoopShapes['plaster']));
Object.defineProperty(CoopAliasedShapes, 'document-check-mark', descriptorConfig(CoopShapes['indkoebsliste_tjek']));
Object.defineProperty(CoopAliasedShapes, 'document-secured', descriptorConfig(CoopShapes['indkoebsliste_cmyk']));
Object.defineProperty(CoopAliasedShapes, 'document-check', descriptorConfig(CoopShapes['indkoebsliste_tjek']));
Object.defineProperty(CoopAliasedShapes, 'document-search', descriptorConfig(CoopShapes['soeg']));
Object.defineProperty(CoopAliasedShapes, 'document-search2', descriptorConfig(CoopShapes['soeg']));
Object.defineProperty(CoopAliasedShapes, 'document-sign', descriptorConfig(CoopShapes['indkoebsliste_plus']));
Object.defineProperty(CoopAliasedShapes, 'document', descriptorConfig(CoopShapes['indkoebsliste_2']));
Object.defineProperty(CoopAliasedShapes, 'documents', descriptorConfig(CoopShapes['indkoebsliste_2']));
Object.defineProperty(CoopAliasedShapes, 'dog', descriptorConfig(CoopShapes['hund']));
Object.defineProperty(CoopAliasedShapes, 'download', descriptorConfig(CoopShapes['download']));
Object.defineProperty(CoopAliasedShapes, 'expand', descriptorConfig(CoopShapes['plus']));
Object.defineProperty(CoopAliasedShapes, 'factory', descriptorConfig(CoopShapes['data']));
Object.defineProperty(CoopAliasedShapes, 'furniture', descriptorConfig(CoopShapes['laenestol']));
Object.defineProperty(CoopAliasedShapes, 'hand-holding', descriptorConfig(CoopShapes['haandtryk']));
Object.defineProperty(CoopAliasedShapes, 'hand-pointing', descriptorConfig(CoopShapes['pil_hoejre']));
Object.defineProperty(CoopAliasedShapes, 'headphones', descriptorConfig(CoopShapes['headset']));
Object.defineProperty(CoopAliasedShapes, 'health-cross', descriptorConfig(CoopShapes['plaster']));
Object.defineProperty(CoopAliasedShapes, 'health-first-aid-kit', descriptorConfig(CoopShapes['plaster']));
Object.defineProperty(CoopAliasedShapes, 'health-heartbeat', descriptorConfig(CoopShapes['hjerte']));
Object.defineProperty(CoopAliasedShapes, 'health-journal', descriptorConfig(CoopShapes['indkoebsliste_2']));
Object.defineProperty(CoopAliasedShapes, 'health-search', descriptorConfig(CoopShapes['soeg']));
Object.defineProperty(CoopAliasedShapes, 'heart-broken', descriptorConfig(CoopShapes['hjerte']));
Object.defineProperty(CoopAliasedShapes, 'heart-plus', descriptorConfig(CoopShapes['hjerte']));
Object.defineProperty(CoopAliasedShapes, 'heart', descriptorConfig(CoopShapes['hjerte']));
Object.defineProperty(CoopAliasedShapes, 'hot-drink', descriptorConfig(CoopShapes['kaffe_kop_varm']));
Object.defineProperty(CoopAliasedShapes, 'house-flats', descriptorConfig(CoopShapes['butik']));
Object.defineProperty(CoopAliasedShapes, 'house-sun', descriptorConfig(CoopShapes['butik']));
Object.defineProperty(CoopAliasedShapes, 'house-tree', descriptorConfig(CoopShapes['butik']));
Object.defineProperty(CoopAliasedShapes, 'house-vacation', descriptorConfig(CoopShapes['butik']));
Object.defineProperty(CoopAliasedShapes, 'ipad-portrait-ad', descriptorConfig(CoopShapes['mobil']));
Object.defineProperty(CoopAliasedShapes, 'ipad-portrait', descriptorConfig(CoopShapes['mobil']));
Object.defineProperty(CoopAliasedShapes, 'ipad', descriptorConfig(CoopShapes['mobil']));
Object.defineProperty(CoopAliasedShapes, 'laptop-ad', descriptorConfig(CoopShapes['webtv']));
Object.defineProperty(CoopAliasedShapes, 'laptop-email', descriptorConfig(CoopShapes['webtv']));
Object.defineProperty(CoopAliasedShapes, 'laptop-mail', descriptorConfig(CoopShapes['webtv']));
Object.defineProperty(CoopAliasedShapes, 'laptop', descriptorConfig(CoopShapes['webtv']));
Object.defineProperty(CoopAliasedShapes, 'light-bulb', descriptorConfig(CoopShapes['lysende-paere']));
Object.defineProperty(CoopAliasedShapes, 'loaded-truck', descriptorConfig(CoopShapes['levering1']));
Object.defineProperty(CoopAliasedShapes, 'lock', descriptorConfig(CoopShapes['laas']));
Object.defineProperty(CoopAliasedShapes, 'locked', descriptorConfig(CoopShapes['laas']));
Object.defineProperty(CoopAliasedShapes, 'logo-flower', descriptorConfig(CoopShapes['coop_app']));
Object.defineProperty(CoopAliasedShapes, 'mail', descriptorConfig(CoopShapes['mail']));
Object.defineProperty(CoopAliasedShapes, 'map-pin', descriptorConfig(CoopShapes['find_butik3']));
Object.defineProperty(CoopAliasedShapes, 'medal', descriptorConfig(CoopShapes['pokal']));
Object.defineProperty(CoopAliasedShapes, 'money-bag-dollar', descriptorConfig(CoopShapes['bonus_velkomst']));
Object.defineProperty(CoopAliasedShapes, 'money-bag-kr', descriptorConfig(CoopShapes['bonus_velkomst']));
Object.defineProperty(CoopAliasedShapes, 'newspaper-folded', descriptorConfig(CoopShapes['print_side']));
Object.defineProperty(CoopAliasedShapes, 'newspaper', descriptorConfig(CoopShapes['print_side']));
Object.defineProperty(CoopAliasedShapes, 'number-1', descriptorConfig(CoopShapes['tal_cirkler_1']));
Object.defineProperty(CoopAliasedShapes, 'number-2', descriptorConfig(CoopShapes['tal_cirkler_2']));
Object.defineProperty(CoopAliasedShapes, 'number-3', descriptorConfig(CoopShapes['tal_cirkler_3']));
Object.defineProperty(CoopAliasedShapes, 'office-chair', descriptorConfig(CoopShapes['laenestol']));
Object.defineProperty(CoopAliasedShapes, 'percent-0', descriptorConfig(CoopShapes['procent']));
Object.defineProperty(CoopAliasedShapes, 'percent-12', descriptorConfig(CoopShapes['procent']));
Object.defineProperty(CoopAliasedShapes, 'percent-15', descriptorConfig(CoopShapes['procent']));
Object.defineProperty(CoopAliasedShapes, 'percent-5', descriptorConfig(CoopShapes['procent']));
Object.defineProperty(CoopAliasedShapes, 'percent-8', descriptorConfig(CoopShapes['procent']));
Object.defineProperty(CoopAliasedShapes, 'person-boy', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-business-large', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-business', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-call', descriptorConfig(CoopShapes['headset']));
Object.defineProperty(CoopAliasedShapes, 'person-girl-teen', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-girl', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-heart-call', descriptorConfig(CoopShapes['headset']));
Object.defineProperty(CoopAliasedShapes, 'person-heart', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-man', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-many', descriptorConfig(CoopShapes['silhuet_x2']));
Object.defineProperty(CoopAliasedShapes, 'person-two', descriptorConfig(CoopShapes['silhuet_x2']));
Object.defineProperty(CoopAliasedShapes, 'person-woman', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person-young-woman', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'person', descriptorConfig(CoopShapes['silhuet2']));
Object.defineProperty(CoopAliasedShapes, 'phone-help-health', descriptorConfig(CoopShapes['telefonroer']));
Object.defineProperty(CoopAliasedShapes, 'phone-circle', descriptorConfig(CoopShapes['telefonroer']));
Object.defineProperty(CoopAliasedShapes, 'phone', descriptorConfig(CoopShapes['telefonroer']));
Object.defineProperty(CoopAliasedShapes, 'pig', descriptorConfig(CoopShapes['sparegris']));
Object.defineProperty(CoopAliasedShapes, 'piggy-bank', descriptorConfig(CoopShapes['sparegris']));
Object.defineProperty(CoopAliasedShapes, 'present', descriptorConfig(CoopShapes['gave']));
Object.defineProperty(CoopAliasedShapes, 'recycle', descriptorConfig(CoopShapes['pant']));
Object.defineProperty(CoopAliasedShapes, 'screen-cloud', descriptorConfig(CoopShapes['sky']));
Object.defineProperty(CoopAliasedShapes, 'screen-idea-bright', descriptorConfig(CoopShapes['lysende-paere']));
Object.defineProperty(CoopAliasedShapes, 'screen-idea', descriptorConfig(CoopShapes['lysende-paere']));
Object.defineProperty(CoopAliasedShapes, 'screen', descriptorConfig(CoopShapes['webtv']));
Object.defineProperty(CoopAliasedShapes, 'found', descriptorConfig(CoopShapes['soeg']));
Object.defineProperty(CoopAliasedShapes, 'search', descriptorConfig(CoopShapes['soeg']));
Object.defineProperty(CoopAliasedShapes, 'security-camera-02', descriptorConfig(CoopShapes['vend_kamera']));
Object.defineProperty(CoopAliasedShapes, 'security-camera', descriptorConfig(CoopShapes['vend_kamera']));
Object.defineProperty(CoopAliasedShapes, 'smart-phone-ad', descriptorConfig(CoopShapes['mobil']));
Object.defineProperty(CoopAliasedShapes, 'smart-phone-chat', descriptorConfig(CoopShapes['mobil']));
Object.defineProperty(CoopAliasedShapes, 'smart-phone-skewed', descriptorConfig(CoopShapes['mobil']));
Object.defineProperty(CoopAliasedShapes, 'smart-phone', descriptorConfig(CoopShapes['mobil']));
Object.defineProperty(CoopAliasedShapes, 'star', descriptorConfig(CoopShapes['3_stjerner']));
Object.defineProperty(CoopAliasedShapes, 'stroller', descriptorConfig(CoopShapes['barnevogn']));
Object.defineProperty(CoopAliasedShapes, 'suitcase', descriptorConfig(CoopShapes['kufferter']));
Object.defineProperty(CoopAliasedShapes, 'table-chairs', descriptorConfig(CoopShapes['baenk']));
Object.defineProperty(CoopAliasedShapes, 'three', descriptorConfig(CoopShapes['bambus']));
Object.defineProperty(CoopAliasedShapes, 'thumbs-up', descriptorConfig(CoopShapes['like']));
Object.defineProperty(CoopAliasedShapes, 'tractor', descriptorConfig(CoopShapes['levering1']));
Object.defineProperty(CoopAliasedShapes, 'train', descriptorConfig(CoopShapes['tog']));
Object.defineProperty(CoopAliasedShapes, 'trash-can', descriptorConfig(CoopShapes['skraldespand']));
Object.defineProperty(CoopAliasedShapes, 'tree', descriptorConfig(CoopShapes['bambus']));
Object.defineProperty(CoopAliasedShapes, 'truck', descriptorConfig(CoopShapes['levering1']));
Object.defineProperty(CoopAliasedShapes, 'tv-ad', descriptorConfig(CoopShapes['tv']));
Object.defineProperty(CoopAliasedShapes, 'tv', descriptorConfig(CoopShapes['tv']));
Object.defineProperty(CoopAliasedShapes, 'unlock', descriptorConfig(CoopShapes['luk_ud']));
Object.defineProperty(CoopAliasedShapes, 'volume-discount', descriptorConfig(CoopShapes['procent_label']));
Object.defineProperty(CoopAliasedShapes, 'eye', descriptorConfig(CoopShapes['tilbudsavis']));
Object.defineProperty(CoopAliasedShapes, 'clip', descriptorConfig(CoopShapes['clips']));

Object.defineProperty(CoopAliasedShapes, 'wheat', descriptorConfig(CoopShapes['glutenfri2']));
Object.defineProperty(CoopAliasedShapes, 'add', descriptorConfig(CoopAliasedShapes['circle-plus']));
Object.defineProperty(CoopAliasedShapes, 'alarm-call', descriptorConfig(CoopAliasedShapes['security-camera-02']));
Object.defineProperty(CoopAliasedShapes, 'alarm-clock', descriptorConfig(CoopAliasedShapes['security-camera-02']));
Object.defineProperty(CoopAliasedShapes, 'alarm', descriptorConfig(CoopAliasedShapes['security-camera-02']));
Object.defineProperty(CoopAliasedShapes, 'alarm2', descriptorConfig(CoopAliasedShapes['security-camera-02']));
Object.defineProperty(CoopAliasedShapes, 'animal-cat', descriptorConfig(CoopAliasedShapes['cat']));
Object.defineProperty(CoopAliasedShapes, 'animal-dog', descriptorConfig(CoopAliasedShapes['dog']));
Object.defineProperty(CoopAliasedShapes, 'assistant', descriptorConfig(CoopAliasedShapes['person-heart-call']));
Object.defineProperty(CoopAliasedShapes, 'avatar-head', descriptorConfig(CoopAliasedShapes['chat-face']));
Object.defineProperty(CoopAliasedShapes, 'avatar', descriptorConfig(CoopAliasedShapes['chat-bot']));
Object.defineProperty(CoopAliasedShapes, 'baby-carrier', descriptorConfig(CoopAliasedShapes['stroller']));
Object.defineProperty(CoopAliasedShapes, 'bag', descriptorConfig(CoopAliasedShapes['attache']));
Object.defineProperty(CoopAliasedShapes, 'boy', descriptorConfig(CoopAliasedShapes['person-boy']));
Object.defineProperty(CoopAliasedShapes, 'broken-heart', descriptorConfig(CoopAliasedShapes['heart-broken']));
Object.defineProperty(CoopAliasedShapes, 'building', descriptorConfig(CoopAliasedShapes['house-flats']));
Object.defineProperty(CoopAliasedShapes, 'bulb', descriptorConfig(CoopAliasedShapes['light-bulb']));
Object.defineProperty(CoopAliasedShapes, 'cal', descriptorConfig(CoopAliasedShapes['calendar']));
Object.defineProperty(CoopAliasedShapes, 'calendar-02-no-date', descriptorConfig(CoopAliasedShapes['calendar-no-date']));
Object.defineProperty(CoopAliasedShapes, 'calendar-02', descriptorConfig(CoopAliasedShapes['calendar']));
Object.defineProperty(CoopAliasedShapes, 'call-center', descriptorConfig(CoopAliasedShapes['person-call']));
Object.defineProperty(CoopAliasedShapes, 'call-center', descriptorConfig(CoopAliasedShapes['person-call']));
Object.defineProperty(CoopAliasedShapes, 'camera', descriptorConfig(CoopAliasedShapes['security-camera']));
Object.defineProperty(CoopAliasedShapes, 'car-collision', descriptorConfig(CoopAliasedShapes['car-crash-two-cars']));
Object.defineProperty(CoopAliasedShapes, 'car-damage', descriptorConfig(CoopAliasedShapes['car-crash-single']));
Object.defineProperty(CoopAliasedShapes, 'car-glass', descriptorConfig(CoopAliasedShapes['car-front-damage']));
Object.defineProperty(CoopAliasedShapes, 'car-sedan', descriptorConfig(CoopAliasedShapes['car-sedan-side']));
Object.defineProperty(CoopAliasedShapes, 'car-side-stationcar', descriptorConfig(CoopAliasedShapes['car-stationcar-side']));
Object.defineProperty(CoopAliasedShapes, 'car-side-van', descriptorConfig(CoopAliasedShapes['car-van-side']));
Object.defineProperty(CoopAliasedShapes, 'car-small', descriptorConfig(CoopAliasedShapes['car-mini-side']));
Object.defineProperty(CoopAliasedShapes, 'car2', descriptorConfig(CoopAliasedShapes['car-sedan-side']));
Object.defineProperty(CoopAliasedShapes, 'carwash', descriptorConfig(CoopAliasedShapes['car-wash']));
Object.defineProperty(CoopAliasedShapes, 'check', descriptorConfig(CoopAliasedShapes['circle-check']));
Object.defineProperty(CoopAliasedShapes, 'circle-exclamation', descriptorConfig(CoopShapes['info']));
Object.defineProperty(CoopAliasedShapes, 'circle-x', descriptorConfig(CoopAliasedShapes['circle-close']));
Object.defineProperty(CoopAliasedShapes, 'clock-forward', descriptorConfig(CoopAliasedShapes['clock-around']));
Object.defineProperty(CoopAliasedShapes, 'coffee', descriptorConfig(CoopAliasedShapes['hot-drink']));
Object.defineProperty(CoopAliasedShapes, 'computer-cloud', descriptorConfig(CoopAliasedShapes['screen-cloud']));
Object.defineProperty(CoopAliasedShapes, 'computer', descriptorConfig(CoopAliasedShapes['computer-screen']));
Object.defineProperty(CoopAliasedShapes, 'conference-call', descriptorConfig(CoopAliasedShapes['phone-circle']));
Object.defineProperty(CoopAliasedShapes, 'contacts', descriptorConfig(CoopAliasedShapes['health-journal']));
Object.defineProperty(CoopAliasedShapes, 'corn', descriptorConfig(CoopAliasedShapes['wheat']));
Object.defineProperty(CoopAliasedShapes, 'credit-cards', descriptorConfig(CoopAliasedShapes['credit-card']));
Object.defineProperty(CoopAliasedShapes, 'creditcard', descriptorConfig(CoopAliasedShapes['credit-card']));
Object.defineProperty(CoopAliasedShapes, 'creditcards', descriptorConfig(CoopAliasedShapes['credit-card']));
Object.defineProperty(CoopAliasedShapes, 'desktop-computer-ad', descriptorConfig(CoopAliasedShapes['laptop-ad']));
Object.defineProperty(CoopAliasedShapes, 'desktop-computer-e-mail', descriptorConfig(CoopAliasedShapes['laptop-email']));
Object.defineProperty(CoopAliasedShapes, 'desktop-computer-mail', descriptorConfig(CoopAliasedShapes['laptop-mail']));
Object.defineProperty(CoopAliasedShapes, 'desktop-computer', descriptorConfig(CoopAliasedShapes['laptop']));
Object.defineProperty(CoopAliasedShapes, 'direct-mail', descriptorConfig(CoopAliasedShapes['laptop-email']));
Object.defineProperty(CoopAliasedShapes, 'document-1', descriptorConfig(CoopAliasedShapes['document-search']));
Object.defineProperty(CoopAliasedShapes, 'easy-chair', descriptorConfig(CoopAliasedShapes['armchair']));
Object.defineProperty(CoopAliasedShapes, 'edit-round', descriptorConfig(CoopAliasedShapes['circle-edit']));
Object.defineProperty(CoopAliasedShapes, 'edit', descriptorConfig(CoopAliasedShapes['circle-edit']));
Object.defineProperty(CoopAliasedShapes, 'envelope', descriptorConfig(CoopAliasedShapes['mail']));
Object.defineProperty(CoopAliasedShapes, 'envelope-open', descriptorConfig(CoopAliasedShapes['mail']));
Object.defineProperty(CoopAliasedShapes, 'error', descriptorConfig(CoopAliasedShapes['circle-close']));
Object.defineProperty(CoopAliasedShapes, 'excluded', descriptorConfig(CoopAliasedShapes['circle-close']));
Object.defineProperty(CoopAliasedShapes, 'expand-map', descriptorConfig(CoopAliasedShapes['expand']));
Object.defineProperty(CoopAliasedShapes, 'face-chat', descriptorConfig(CoopAliasedShapes['chat-face']));
Object.defineProperty(CoopAliasedShapes, 'first-prize', descriptorConfig(CoopAliasedShapes['medal']));
Object.defineProperty(CoopAliasedShapes, 'food-fork-knife', descriptorConfig(CoopAliasedShapes['cutlery']));
Object.defineProperty(CoopAliasedShapes, 'future', descriptorConfig(CoopAliasedShapes['screen-idea']));
Object.defineProperty(CoopAliasedShapes, 'girl-02', descriptorConfig(CoopAliasedShapes['person-girl']));
Object.defineProperty(CoopAliasedShapes, 'girl', descriptorConfig(CoopAliasedShapes['person-girl-teen']));
Object.defineProperty(CoopAliasedShapes, 'hand-pointing-right', descriptorConfig(CoopAliasedShapes['hand-pointing']));
Object.defineProperty(CoopAliasedShapes, 'handt', descriptorConfig(CoopAliasedShapes['hand-pointing']));
Object.defineProperty(CoopAliasedShapes, 'head', descriptorConfig(CoopAliasedShapes['chat-face']));
Object.defineProperty(CoopAliasedShapes, 'heart-rate', descriptorConfig(CoopAliasedShapes['health-heartbeat']));
Object.defineProperty(CoopAliasedShapes, 'holding-hands', descriptorConfig(CoopAliasedShapes['hand-holding']));
Object.defineProperty(CoopAliasedShapes, 'hot-drink-cup', descriptorConfig(CoopAliasedShapes['hot-drink']));
Object.defineProperty(CoopAliasedShapes, 'house', descriptorConfig(CoopAliasedShapes['house-sun']));
Object.defineProperty(CoopAliasedShapes, 'house-sunny', descriptorConfig(CoopAliasedShapes['house-sun']));
Object.defineProperty(CoopAliasedShapes, 'hq', descriptorConfig(CoopAliasedShapes['house-tree']));
Object.defineProperty(CoopAliasedShapes, 'identificer', descriptorConfig(CoopAliasedShapes['health-search']));
Object.defineProperty(CoopAliasedShapes, 'included', descriptorConfig(CoopAliasedShapes['circle-check']));
Object.defineProperty(CoopAliasedShapes, 'included', descriptorConfig(CoopAliasedShapes['circle-check']));
Object.defineProperty(CoopAliasedShapes, 'information-i', descriptorConfig(CoopShapes['info']));
Object.defineProperty(CoopAliasedShapes, 'ipad-vertical-ad', descriptorConfig(CoopAliasedShapes['ipad-portrait-ad']));
Object.defineProperty(CoopAliasedShapes, 'ipad-vertical', descriptorConfig(CoopAliasedShapes['ipad-portrait']));
Object.defineProperty(CoopAliasedShapes, 'knife-fork', descriptorConfig(CoopAliasedShapes['cutlery']));
Object.defineProperty(CoopAliasedShapes, 'locked', descriptorConfig(CoopAliasedShapes['lock']));
Object.defineProperty(CoopAliasedShapes, 'loesoere', descriptorConfig(CoopAliasedShapes['furniture']));
Object.defineProperty(CoopAliasedShapes, 'magnifying-glass', descriptorConfig(CoopAliasedShapes['search']));
Object.defineProperty(CoopAliasedShapes, 'man', descriptorConfig(CoopAliasedShapes['person-man']));
Object.defineProperty(CoopAliasedShapes, 'meeting', descriptorConfig(CoopAliasedShapes['table-chairs']));
Object.defineProperty(CoopAliasedShapes, 'minus', descriptorConfig(CoopAliasedShapes['circle-minus']));
Object.defineProperty(CoopAliasedShapes, 'mobile-message', descriptorConfig(CoopAliasedShapes['smart-phone-chat']));
Object.defineProperty(CoopAliasedShapes, 'money-bag-kroner', descriptorConfig(CoopAliasedShapes['money-bag-kr']));
Object.defineProperty(CoopAliasedShapes, 'money-bag', descriptorConfig(CoopAliasedShapes['money-bag-dollar']));
Object.defineProperty(CoopAliasedShapes, 'money-note', descriptorConfig(CoopAliasedShapes['bill']));
Object.defineProperty(CoopAliasedShapes, 'money-single', descriptorConfig(CoopAliasedShapes['bill']));
Object.defineProperty(CoopAliasedShapes, 'money-stack', descriptorConfig(CoopAliasedShapes['bills']));
Object.defineProperty(CoopAliasedShapes, 'movables', descriptorConfig(CoopAliasedShapes['furniture']));
Object.defineProperty(CoopAliasedShapes, 'my-car', descriptorConfig(CoopAliasedShapes['car-mini-side']));
Object.defineProperty(CoopAliasedShapes, 'negative', descriptorConfig(CoopAliasedShapes['circle-close']));
Object.defineProperty(CoopAliasedShapes, 'ok-green', descriptorConfig(CoopAliasedShapes['circle-check']));
Object.defineProperty(CoopAliasedShapes, 'open', descriptorConfig(CoopAliasedShapes['unlock']));
Object.defineProperty(CoopAliasedShapes, 'outdoor', descriptorConfig(CoopAliasedShapes['ipad-portrait']));
Object.defineProperty(CoopAliasedShapes, 'paper-1', descriptorConfig(CoopAliasedShapes['newspaper']));
Object.defineProperty(CoopAliasedShapes, 'paper-2', descriptorConfig(CoopAliasedShapes['newspaper-folded']));
Object.defineProperty(CoopAliasedShapes, 'person-+', descriptorConfig(CoopAliasedShapes['person-many']));
Object.defineProperty(CoopAliasedShapes, 'person-care', descriptorConfig(CoopAliasedShapes['person-heart-call']));
Object.defineProperty(CoopAliasedShapes, 'person-closeup', descriptorConfig(CoopAliasedShapes['person-man']));
Object.defineProperty(CoopAliasedShapes, 'person-no', descriptorConfig(CoopAliasedShapes['person-man']));
Object.defineProperty(CoopAliasedShapes, 'persons', descriptorConfig(CoopAliasedShapes['person-two']));
Object.defineProperty(CoopAliasedShapes, 'persons-many', descriptorConfig(CoopAliasedShapes['person-many']));
Object.defineProperty(CoopAliasedShapes, 'pin', descriptorConfig(CoopAliasedShapes['map-pin']));
Object.defineProperty(CoopAliasedShapes, 'place', descriptorConfig(CoopAliasedShapes['map-pin']));
Object.defineProperty(CoopAliasedShapes, 'plus-circle', descriptorConfig(CoopAliasedShapes['circle-plus']));
Object.defineProperty(CoopAliasedShapes, 'positive', descriptorConfig(CoopAliasedShapes['circle-check']));
Object.defineProperty(CoopAliasedShapes, 'remove', descriptorConfig(CoopAliasedShapes['circle-minus']));
Object.defineProperty(CoopAliasedShapes, 'school', descriptorConfig(CoopAliasedShapes['house-tree']));
Object.defineProperty(CoopAliasedShapes, 'screen-light', descriptorConfig(CoopAliasedShapes['screen-idea-bright']));
Object.defineProperty(CoopAliasedShapes, 'service', descriptorConfig(CoopAliasedShapes['car-service']));
Object.defineProperty(CoopAliasedShapes, 'sign-document', descriptorConfig(CoopAliasedShapes['document-sign']));
Object.defineProperty(CoopAliasedShapes, 'sofa', descriptorConfig(CoopAliasedShapes['couch']));
Object.defineProperty(CoopAliasedShapes, 'surveillance', descriptorConfig(CoopAliasedShapes['security-camera']));
Object.defineProperty(CoopAliasedShapes, 'table-and-chairs', descriptorConfig(CoopAliasedShapes['table-chairs']));
Object.defineProperty(CoopAliasedShapes, 'to-do', descriptorConfig(CoopAliasedShapes['document-check-mark']));
Object.defineProperty(CoopAliasedShapes, 'trashcan-1', descriptorConfig(CoopAliasedShapes['trash-can']));
Object.defineProperty(CoopAliasedShapes, 'trashcan-2', descriptorConfig(CoopAliasedShapes['circle-trash']));
Object.defineProperty(CoopAliasedShapes, 'travel-scenery', descriptorConfig(CoopAliasedShapes['cake']));
Object.defineProperty(CoopAliasedShapes, 'travel-suitcase-3', descriptorConfig(CoopAliasedShapes['carry-on']));
Object.defineProperty(CoopAliasedShapes, 'tree', descriptorConfig(CoopAliasedShapes['three']));
Object.defineProperty(CoopAliasedShapes, 'vacation-backpack', descriptorConfig(CoopAliasedShapes['backpack']));
Object.defineProperty(CoopAliasedShapes, 'vacation-house', descriptorConfig(CoopAliasedShapes['house-vacation']));
Object.defineProperty(CoopAliasedShapes, 'van', descriptorConfig(CoopAliasedShapes['car-van-side']));
Object.defineProperty(CoopAliasedShapes, 'weather-cloud', descriptorConfig(CoopAliasedShapes['cloud']));
Object.defineProperty(CoopAliasedShapes, 'weather-star', descriptorConfig(CoopAliasedShapes['star']));
Object.defineProperty(CoopAliasedShapes, 'webcam', descriptorConfig(CoopAliasedShapes['security-camera-02']));
Object.defineProperty(CoopAliasedShapes, 'woman', descriptorConfig(CoopAliasedShapes['person-woman']));
Object.defineProperty(CoopAliasedShapes, 'woman2', descriptorConfig(CoopAliasedShapes['person-young-woman']));
Object.defineProperty(CoopAliasedShapes, 'young-woman', descriptorConfig(CoopAliasedShapes['person-young-woman']));

export {CoopAliasedShapes};
